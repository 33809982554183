import _ from 'lodash'
import {Account} from 'p2p-abi-lib'
import {useEffect, useState} from 'react'
import Web3 from 'web3'
import {active_Url} from './ChatContainer'
const _baseUrl = 'http://44.197.21.123:8080/'
export const LoginChatRoom = ({chatWith, onChatOpen}: any) => {
  const [isAccountOnlineForChat, setisAccountOnlineForChat] = useState(false)
  async function checkAccountOnline() {
    const _account = await localStorage.getItem('loggedInUser')
    const _token = localStorage.getItem('loggedInUserToken')
    if (_account && _token) {
      setisAccountOnlineForChat(true)
    } else {
      setisAccountOnlineForChat(false)
    }
  }
  useEffect(() => {
    checkAccountOnline()
  }, [])

  const [account, setaccount] = useState<any>(undefined)
  async function init() {
    const _account = await Account.getAccount()
    await _account
    setaccount(await _account?.data)
  }

  function onLoginSuccess({account, token}: any) {
    localStorage.setItem('loggedInUser', account)
    localStorage.setItem('loggedInUserToken', token)
    checkAccountOnline()
  }
  async function signIn(account: any) {
    var users: any = await fetch(active_Url + 'accounts/' + account).then((res) => res.json())
    await users
    if (Object.keys(users.Nonce).length === 0) {
      return
    }

    const _signature = await getSignature(users?.Address, users?.Nonce)
    await _signature

    if (_signature) {
      const _authenticate: any = await authenticate({account, signature: _signature})

      await _authenticate
      if (_authenticate?.data && _authenticate?.data?.token) {
        onLoginSuccess({account: account, token: _authenticate?.data?.token})
      }
    }
  }
  async function getSignature(address: any, nonce: any) {
    const BASE_WEB3_URL = 'http://localhost:8545'
    const _web3 = new Web3(Web3.givenProvider || BASE_WEB3_URL)

    const _result = await _web3.eth.personal.sign(
      _web3.utils.fromUtf8(`I am signing my one-time nonce: ${nonce}`),
      address,
      ''
    )

    return _result
  }
  async function authenticate({account, signature}: any) {
    /*   https://cryptop2pchat.herokuapp.com */

    return await fetch(active_Url+'auth-jwt', {
      body: JSON.stringify({account, signature}),
      headers: {
        'Content-Type': 'application/json',
      },

      method: 'POST',
    })
      .then(async (response) => {
        if (response.status == 200) {
          var body = await response.json()
          /*           */

          return {data: body}
        } else {
          return {error: 'login error'}
        }
      })

      .catch((error: any) => {
        return {error: error?.message}
      })
  }
  useEffect(() => {}, [])
  useEffect(() => {
    if (account) {
      signIn(account)
    }
  }, [account])

  return (
    <div>
      {isAccountOnlineForChat === false ? (
        <button
          className='btn btn-danger px-6 align-self-center text-nowrap'
          onClick={(e: any) => {
            init()
          }}
        >
          Chat Login
        </button>
      ) : (
        <div className='btn btn-success px-6 align-self-center text-nowrap' onClick={onChatOpen}>
          Chat With{' '}
          {_.truncate(chatWith, {
            length: 8,
            omission: '***',
          })}
        </div>
      )}
    </div>
  )
}
