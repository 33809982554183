import React from 'react'
import { decimalConverter } from '../../../../app/modules/crypto/helpers/bigintConvertoer'

type Props = {}

const DecimalValue = ({ value }: any) => {
    return <>{decimalConverter(value)}</>
}

export default DecimalValue
