import React from 'react'

type Props = {
  offerType:any,
  fiat:any,
  cryptoType:any
}

export const HeaderDetailBanner = ({offerType,cryptoType,fiat}: Props) => {
  return (
    <div className="container ">
    <div className="row">
        <div className="col-lg-12 text-center text-white">
      <div className="text-center text-capitalize" style={{ fontSize: 25, fontWeight: "bold", textAlign: "center" }}><span className='text-lowercase text-capitalize'>{offerType}</span> {cryptoType}  from {fiat}</div>
      <div className="text-center" style={{ fontSize: 14, textAlign: "center" }}>Buy and sell Bitcoin safely and easily on P2P. Find the best offer below and buy and sell BTC with Your Preferred Payment Methods today.
      </div>
        </div></div>
    </div>
  )
}