/* eslint-disable jsx-a11y/anchor-is-valid */
import _, {isEmpty} from 'lodash'
import {useEffect} from 'react'

import {BiMoney, BiQrScan, BiWallet} from 'react-icons/bi'
const Step2 = ({data, updateInput, hasError}: any) => {
  useEffect(() => {}, [data])

  const update = (e: any) => {
    var _paymentMethods: any[] = data?.paymentMethod

    if (_.includes(_paymentMethods, e.target.value)) {
      var __paymentMethods: any = _.without(_paymentMethods, e.target.value)

      updateInput({key: 'paymentMethod', value: __paymentMethods})
    } else {
      _paymentMethods.push(e.target.value)
      updateInput({key: 'paymentMethod', value: _paymentMethods})
    }
  }

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className=' '>
        {/*begin::Form Group */}
        <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center fs-6 fw-semibold mb-8'>
            <span className='required'>Select Fiat Payment Method</span>
          </label>
          {/* end::Label */}
          {/*begin:Option */}

          <div className='d-flex flex-row justify-content-between'>
            <div className='flex-grow-1 mx-4'>
              <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-25px me-3'>
                    <span className='symbol-label bg-light-warning'>
                      <BiQrScan size={16} color='black' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Netbanking</span>
                    <span className='fs-7 text-muted'>IMPS / NEFT</span>
                  </span>
                </span>

                <div className='form-check form-switch form-switch-md form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='step2'
                    value='netbanking'
                    checked={_.includes(data.paymentMethod, 'netbanking')}
                    onChange={(e) => update(e)}
                  />
                </div>
              </label>
            </div>
            <div className='flex-grow-1 mx-4'>
              <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-25px me-3'>
                    <span className='symbol-label bg-light-warning'>
                      <BiWallet size={16} color='black' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Walelt</span>
                    <span className='fs-7 text-muted'>Wallet Transfer</span>
                  </span>
                </span>

                <div className='form-check form-switch form-switch-md form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='step2'
                    value='wallet'
                    checked={_.includes(data.paymentMethod, 'wallet')}
                    onChange={(e) => update(e)}
                  />
                </div>
              </label>
            </div>
          </div>

          <div className='d-flex flex-row justify-content-between my-4'>
            <div className='flex-grow-1 mx-4'>
              <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-25px me-3'>
                    <span className='symbol-label bg-light-warning  p-1'>
                      <BiQrScan size={16} color='black' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>UPI</span>
                    <span className='fs-7 text-muted'>Almost Immediate</span>
                  </span>
                </span>

                <div className='form-check form-switch form-switch-md form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='step2'
                    value='upi'
                    checked={_.includes(data.paymentMethod, 'upi')}
                    onChange={(e) => update(e)}
                  />
                </div>
              </label>
            </div>
            <div className='flex-grow-1 mx-4'>
              <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-25px me-3'>
                    <span className='symbol-label bg-light-warning  p-1'>
                      <BiMoney size={16} color='black' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Cash</span>
                    <span className='fs-7 text-muted'>Immeidate</span>
                  </span>
                </span>

                <div className='form-check form-switch form-switch-md form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='step2'
                    value='cash'
                    checked={_.includes(data.paymentMethod, 'cash')}
                    onChange={(e) => update(e)}
                  />
                </div>
              </label>
            </div>
          </div>

         
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {Step2}
