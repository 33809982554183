import {isEmpty} from 'lodash'
import {useRef, useState, useEffect, useContext} from 'react'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import {CreateOfferContext} from './CreateOfferProvider'
import {Step1} from './buy-steps/Step1'
import {Step2} from './buy-steps/Step2'
import {Step3} from './buy-steps/Step3'
import {Step5} from './buy-steps/Step5'

type Props = {}

function CreateBuyOfferContainer({submit, handleClose}: any) {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const {offerInputs, update, onCreateBuyOrderSubmit, orderProcessingStatus}: any =
    useContext<any>(CreateOfferContext)
  const noError = {flag: false, message: ''}
  const [hasError, setError] = useState(noError)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  useEffect(() => {
    if (orderProcessingStatus?.initiated && orderProcessingStatus?.status === 2) {
      handleClose()
    }
  }, [orderProcessingStatus])

  useEffect(() => {
    loadStepper()
  }, [])
  function updateInput(data: any) {
    update(data)
  }

  const checkStep1 = (): any => {
    if (isEmpty(offerInputs?.fiat) || isEmpty(offerInputs?.token)) {
      return {flag: true, message: ' select fiat or token'}
    }
    return noError
  }

  const checkStep2 = (): any => {
    if (isEmpty(offerInputs?.paymentMethod)) {
      return {flag: true, message: ' Atleast one paymethod is required'}
    }

    return noError
  }
  const checkStep3 = (): any => {
    console.log('offerInputs', offerInputs)
    if (offerInputs?.price === '') {
      return {flag: false, message: 'Fiat Price is required'}
    }

    if (offerInputs?.totalValue === '') {
      return {flag: true, message: ' Token Value is required'}
    }

    if (offerInputs?.minValue === '') {
      return {flag: true, message: ' Min Token Value is required'}
    }
    if (offerInputs?.maxValue === '') {
      return {flag: true, message: ' Max Token Value is required'}
    }
    if (Number(offerInputs?.maxValue) > Number(offerInputs?.totalValue)) {
      return {flag: true, message: 'You cant enter max tokens above value of required token'}
    }

    return noError
  }

  const checkStep4 = (): any => {
    return noError
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setError(noError)
    if (!stepper.current) {
      return
    }
    console.log('index', stepper.current.getCurrentStepIndex())
    if (stepper.current.getCurrentStepIndex() === 1) {
      const _steError = checkStep1()

      if (_steError?.flag === true) {
        console.log('_steError', _steError)
        setError(_steError)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 2) {
      const _steError = checkStep2()
      if (_steError?.flag === true) {
        console.log('_steError', _steError)
        setError(_steError)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      const _steError = checkStep3()
      if (_steError?.flag === true) {
        setError(_steError)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 4) {
      const _steError = checkStep4()
      if (_steError?.flag === true) {
        setError(_steError)
        return
      }
    }
    stepper.current.goNext()
  }
  return (
    <>
      {orderProcessingStatus?.initiated && (
        <div
          className={`card   `}
          style={{
            padding: 0,
            minHeight: 400,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <span className='indicator-progress' style={{display: 'block', paddingTop: 50}}>
            {orderProcessingStatus?.stepMessage}...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      )}
      {!orderProcessingStatus?.initiated && (
        <div className='modal-body '>
          {/*begin::Stepper */}
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            {/* begin::Aside*/}
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto   '>
              {/* begin::Nav*/}
              <div className='stepper-nav ps-lg-5'>
                {/* begin::Step 1*/}
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-30px rounded'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <span className='fs-6'>Choose Trade</span>

                      <div className='stepper-desc fs-8 '>Select Fiat and Tokens</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 1*/}

                {/* begin::Step 2*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    {/* begin::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <span className='stepper-title fs-6'>Payment Method</span>

                      <div className='stepper-desc fs-8'>Choose Fiat Payment Method</div>
                    </div>
                    {/* begin::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 2*/}

                {/* begin::Step 3*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <span className='stepper-title fs-6'>Pricing</span>
                      <div className='stepper-desc fs-8'>Set Your Pricing </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 3*/}

                {/* begin::Step 5*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <span className='stepper-number'>4</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <span className='stepper-title fs-6'>Notes</span>

                      <div className='stepper-desc fs-8'>Send a note with your offer.</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Step 5*/}
              </div>
              {/* end::Nav*/}
            </div>
            {/* begin::Aside*/}

            {/*begin::Content */}
            <div className='flex-grow-1 ps-10'>
              {/*begin::Form */}
              <form noValidate id='kt_modal_create_app_form'>
                <Step1 data={offerInputs} updateInput={updateInput} hasError={hasError} />
                <Step2 data={offerInputs} updateInput={updateInput} hasError={hasError} />
                <Step3 data={offerInputs} updateInput={updateInput} hasError={hasError} />
                <Step5 data={offerInputs} updateInput={updateInput} hasError={hasError} />
                {/* <Step6 /> */}
                {/*begin::Actions */}
                {hasError?.flag === true && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      {hasError?.message}
                    </div>
                  </div>
                )}
                <div className='d-flex flex-stack pt-10'>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={prevStep}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1'
                      />{' '}
                      Back
                    </button>
                  </div>
                  <div>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      data-kt-stepper-action='submit'
                      onClick={(e: any) => {
                        onCreateBuyOrderSubmit(e)
                      }}
                    >
                      {orderProcessingStatus?.initiated ? (
                        <>
                          {' '}
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Creating order ...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </>
                      ) : (
                        <>
                          <span>Submit</span>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </>
                      )}
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      data-kt-stepper-action='next'
                      onClick={nextStep}
                    >
                      Next
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-1 me-0'
                      />
                    </button>
                  </div>
                </div>
                {/*end::Actions */}
              </form>
              {/*end::Form */}
            </div>
            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
      )}
    </>
  )
}

export default CreateBuyOfferContainer
