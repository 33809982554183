/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../helpers'
import {OfferRow} from '../OfferRow'

type Props = {
  className?: string
  offers: any
  filters: any
}

const OffersListsV1: React.FC<Props> = ({className, offers, filters}) => {
  const [currentSelection, setcurrentSelection] = useState<any>()

  function onOfferSelected(data: any) {
    if (!currentSelection) {
      setcurrentSelection(data)
    } else {
      setcurrentSelection(undefined)
    }
  }

  useEffect(() => {}, [currentSelection])
  useEffect(() => {
    setcurrentSelection(undefined)
  }, [offers])

  return (
    <>
      <div className={`card ${className}`} style={{padding: 0, minHeight: 300}}>
        <div className='card-body '>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table  align-middle gs-0 gy-4'
              style={{width: '100%'}}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted fs-7'>
                  <th className='min-w-100px'>{filters?.offerType==="BUY"?"Seller":"Buyer"}</th>
                  <th className='min-w-100px'>Price / {filters?.crypto}</th>

                  <th className='min-w-100px'>Tran.. Limit</th>
                  <th className='min-w-100px'>Available</th>
                  <th className='min-w-100px'>Collateral</th>
                  <th className='min-w-100px'>Payment Methods</th>
                  <th className='min-w-100px '>Seller Rating</th>
                </tr>
              </thead>

              <tbody style={{margin:"15 0"}}>
                {offers?.map((offer: any) => {
                  return (
                    <OfferRow
                      {...offer}
                      offerType={filters?.offerType}
                      cryptoType={filters?.crypto}
                      onOfferSelected={onOfferSelected}
                      currentSelection={currentSelection}
                      setcurrentSelection={setcurrentSelection}
                    />
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {OffersListsV1}
