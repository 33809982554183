/* eslint-disable jsx-a11y/anchor-is-valid */
import {StepProps} from '../IAppModels'

const Step3 = ({data, updateInput, hasError}: any) => {
  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-1' data-kt-stepper-element='content'>
        <div className=''>
          <div className='mb-10'>
            <div className='form-group m-0'>
              <label className='py-4'>Pricing Type:</label>
              <div className='row'>
                <div className='col-lg-6'>
                  <label className='option'>
                    <span className='option-control'>
                      <span className='radio'>
                        <input
                          type='radio'
                          name='fixedpricing'
                          value='fixedpricing'
                          checked={true}
                          onChange={() => {}}
                        />
                        <span></span>
                      </span>
                    </span>
                    <span className='option-label'>
                      <span className='option-head'>
                        <span className='option-title px-2'>Fixed Pricing</span>
                        {/* <span className='option-focus'>Free</span> */}
                      </span>
                    </span>
                  </label>
                </div>
                <div className='col-lg-6'>
                  <label className='option'>
                    <span className='option-control'>
                      <span className='radio'>
                        <input
                          type='radio'
                          name='fixedpricing'
                          value='dynamicpricing'
                          disabled
                          checked={false}
                          onChange={() => {}}
                        />
                        <span></span>
                      </span>
                    </span>
                    <span className='option-label'>
                      <span className='option-head'>
                        <span className='option-title px-2 fs-8'>
                          Dynamic Pricing ( coming soon)
                        </span>
                        {/* <span className='option-focus'>$&nbsp;8.00</span> */}
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/*begin::Form Group */}

          <div className='fv-row my-5'>
            <label className='fs-7 fw-semibold mb-2'>Price ( in Fiat ) </label>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7'
              name='price'
              value={data?.price}
              onChange={(e) => updateInput({key: 'price', value: e.target.value})}
            />
          </div>
          <div className='fv-row my-5'>
            <label className='fs-5 fw-semibold fs-7'>Total Value (Tokens ) </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7'
              name='totalValue'
              value={data?.totalValue}
              onChange={(e) => updateInput({key: 'totalValue', value: e.target.value})}
            />
          </div>
          {/*end::Form Group */}
          <div className='d-flex justify-content-between'>
            {/*begin::Form Group */}

            <div className='fv-row   '>
              <label className='fs-5 fw-semibold mb-2 fs-7'>Minimum Token</label>

              <input
                type='text'
                className='form-control form-control-lg form-control-solid fs-7'
                name='minValue'
                value={data.minValue}
                onChange={(e) => {
                  updateInput({key: 'minValue', value: e.target.value})
                }}
              />
            </div>
            {/*end::Form Group */}

            {/*begin::Form Group */}

            <div className='fv-row   mx-10'>
              <label className='fs-5 fw-semibold mb-2 fs-7'>Maximum Token</label>

              <input
                type='text'
                className='form-control form-control-lg form-control-solid fs-7'
                name='maxValue'
                value={data.maxValue}
                onChange={(e) => {
                  updateInput({key: 'maxValue', value: e.target.value})
                }}
              />
            </div>
            {/*end::Form Group */}
          </div>
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export {Step3}
