import {bigIntConverter} from './bigintConvertoer'
import {OfferTypeEnum} from '../../../../_metronic/partials/widgets/crypto/OfferFilterComponent'
import {Account, Buy, Sell} from 'p2p-abi-lib'

export async function setMatchOrder(
  offerType: any,
  token: any,
  fiat: any,
  position: any,
  value: any
) {
  if (offerType === OfferTypeEnum.SELL) {
    const _approval = await Account.buyTokenApproval({
      TOKEN_NAME: token,
      APPROVAL_VALUE: bigIntConverter(value).toString(),
    })

    await _approval

    if (_approval?.data?.blockHash) {
      var __result = await Buy.matchBuyOrder({
        SELLING_TOKEN_ADDRESS: token,
        FIAT: fiat,
        POSITION: position,
        AMOUNT: bigIntConverter(value).toString(),
      })
      await __result

      return {data: __result?.data, error: __result?.error}
    } else {
      return {data: _approval?.data, error: _approval?.error}
    }
  } else {
    var _result = await Sell.matchSellOrder({
      TOKEN_NAME: token,
      FIAT_NAME: fiat,
      POSITION: position,
      AMOUNT: bigIntConverter(value).toString(),
    })
    await _result

    return {data: _result?.data, error: _result?.error}
  }
}
