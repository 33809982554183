/* eslint-disable jsx-a11y/anchor-is-valid */

import {TOKEN_ENUM, FIAT_ENUM} from 'p2p-abi-lib/lib/constants/tokens'
import {StepProps} from '../IAppModels'

const Step1 = ({data, updateInput, hasError}: any) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>I want to Buy</span>
            {/* className='required' */}
          </label>

          <select
            className='form-select form-select-solid'
            aria-label='Select example'
            placeholder='Choose Crypto'
            defaultValue={data.token}
            value={data.token}
            onChange={(e) => {
              updateInput({key: 'token', value: e.target.value})
            }}
          >
            {Object.values(TOKEN_ENUM).map((crypto: any) => {
              return <option value={crypto}>{crypto}</option>
            })}
          </select>
          {/*  {!data.token && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                Crypto is required
              </div>
            </div>
          )} */}
        </div>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span>I will recieve in</span>
          </label>
          <select
            className='form-select form-select-solid'
            aria-label='Select example'
            placeholder='Choose FIAT'
            value={data.fiat}
            defaultValue=''
            onChange={(e) => updateInput({key: 'fiat', value: e.target.value})}
          >
            {Object.values(FIAT_ENUM).map((val: any) => {
              return <option value={val}>{val}</option>
            })}
          </select>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {Step1}
