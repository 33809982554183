import {TOKEN_ENUM, FIAT_ENUM, Buy, Account, Sell} from 'p2p-abi-lib'
import React, {useEffect, useReducer, useState} from 'react'
import {bigIntConverter} from '../../helpers/bigintConvertoer'

export interface ICreateOfferInput {
  token: TOKEN_ENUM
  fiat: FIAT_ENUM
  paymentMethod: string[]
  price: any
  minValue: any
  maxValue: any
  collateral: any
  notes: any
  totalValue: any
}

export const defaultSellOfferInputs: ICreateOfferInput = {
  token: TOKEN_ENUM.LINK,
  fiat: FIAT_ENUM.INR,
  paymentMethod: [],
  price: 1,
  minValue: 1,
  maxValue: 1,
  collateral: 0,
  notes: '',
  totalValue: 1,
}
export interface IProcessStatus {
  initiated: boolean
  totalSteps: number
  currentStep: number
  stepMessage: string
  errorMessage: string
  status: number
}

export const initProcessStatus: IProcessStatus = {
  initiated: false,
  totalSteps: 3,
  currentStep: 0,
  stepMessage: '',
  errorMessage: '',
  status: 0,
}
export const CreateOfferContext = React.createContext<
  | {
      offerInputs: any
      update: (data: any) => {} | void
      onCreateSellOrderSubmit: (data: any) => {}
      onCreateBuyOrderSubmit: (data: any) => {} | void
      orderProcessingStatus: IProcessStatus
    }
  | undefined
>(undefined)
const offerReducer = (state: ICreateOfferInput, {type, payload}: any) => {
  switch (type) {
    case 'INPUT_CHANGE':
      return {...state, [payload.key]: payload?.value}
    default:
      return state
  }
}
const CreateOfferProvider = ({children}: any) => {
  const [orderProcessingStatus, setorderProcessingStatus] =
    useState<IProcessStatus>(initProcessStatus)
  const [offerInputs, dispatch] = useReducer(offerReducer, defaultSellOfferInputs)
  useEffect(() => {}, [offerInputs])
  function update(data: any) {
    dispatch({type: 'INPUT_CHANGE', payload: data})
  }

  async function onCreateBuyOrderSubmit() {
    setorderProcessingStatus({
      initiated: true,
      totalSteps: 3,
      currentStep: 1,
      stepMessage: 'New Buy Order Creating ....',
      errorMessage: '',
      status: 0,
    })
    try {
      /*  const _approval = await Account.tokenApproval({
        TOKEN_NAME: offerInputs?.token,
        APPROVAL_VALUE: bigIntConverter(offerInputs?.totalValue).toString(),
      })

      await _approval */

      const _result = await Buy.createBuyOrder({
        TOKEN_NAME: offerInputs?.token,
        FIAT_NAME: offerInputs?.fiat,
        COLLATRAL: bigIntConverter(offerInputs?.collateral).toString(),
        TOTAL_VALUE: bigIntConverter(offerInputs?.totalValue).toString(),
        MIN_BUY_VALUE: bigIntConverter(offerInputs?.minValue).toString(),
        MAX_BUY_VALUE: bigIntConverter(offerInputs?.maxValue).toString(),
        PRICE: offerInputs?.price.toString(),
        NOTES: JSON.stringify({
          paymentMethod: JSON.stringify(offerInputs?.paymentMethod),
          offerNotes: offerInputs?.notes,
        }),
      })
      await _result

      if (_result?.data && _result?.data?.order) {
        setorderProcessingStatus({
          initiated: true,
          totalSteps: 3,
          currentStep: 3,
          stepMessage: ' New Buy Contract Confirmed',
          errorMessage: '',
          status: 2,
        })
      } else {
        setorderProcessingStatus({
          initiated: true,
          totalSteps: 3,
          currentStep: 3,
          stepMessage: ' New Buy Contract Failed',
          errorMessage: _result?.error?.message,
          status: 1,
        })
      }
    } catch (error: any) {
      setorderProcessingStatus({
        initiated: true,
        totalSteps: 3,
        currentStep: 2,
        stepMessage: ' New Buy Contract Failed',
        errorMessage: error?.message,
        status: 1,
      })
    }
  }

  async function onCreateSellOrderSubmit() {
    setorderProcessingStatus({
      initiated: true,
      totalSteps: 3,
      currentStep: 1,
      stepMessage: 'Sell Order Creation Initated....',
      errorMessage: '',
      status: 0,
    })

    const _account: any = await Account.getAccount()
    await _account

    const _approval = await Account.sellTokenApproval({
      TOKEN_NAME: offerInputs?.token,
      APPROVAL_VALUE: bigIntConverter(offerInputs?.totalValue).toString(),
    })

    await _approval

    if (_approval?.data && _approval?.data?.blockHash) {
      setorderProcessingStatus({
        initiated: true,
        totalSteps: 3,
        currentStep: 2,
        stepMessage: 'Token Approval initiated.......',
        errorMessage: '',
        status: 0,
      })
    }
    try {
      const _result = await Sell.createSellOrder({
        TOKEN_NAME: offerInputs?.token,
        FIAT_NAME: offerInputs?.fiat,
        COLLATRAL: bigIntConverter(offerInputs?.collateral).toString(),
        TOTAL_VALUE: bigIntConverter(offerInputs?.totalValue).toString(),
        MIN_SELL_VALUE: bigIntConverter(offerInputs?.minValue).toString(),
        MAX_SELL_VALUE: bigIntConverter(offerInputs?.maxValue).toString(),
        PRICE: offerInputs?.price.toString(),
        NOTES: JSON.stringify({
          paymentMethod: JSON.stringify(offerInputs?.paymentMethod),
          offerNotes: offerInputs?.notes,
        }),
      })
      await _result

      if (_result?.data && _result?.data?.order) {
        setorderProcessingStatus({
          initiated: true,
          totalSteps: 3,
          currentStep: 3,
          stepMessage: ' New Sell Contract Confirmed',
          errorMessage: '',
          status: 2,
        })
      } else {
        setorderProcessingStatus({
          initiated: true,
          totalSteps: 3,
          currentStep: 2,
          stepMessage: ' New Sell Contract Failed',
          errorMessage: _result?.error?.message,
          status: 1,
        })
      }
    } catch (error: any) {
      setorderProcessingStatus({
        initiated: true,
        totalSteps: 3,
        currentStep: 2,
        stepMessage: ' New Sell Contract Failed',
        errorMessage: error?.message,
        status: 1,
      })
    }
    /*  } */
  }
  const value: any = {
    offerInputs,
    update,

    onCreateSellOrderSubmit,
    onCreateBuyOrderSubmit,
    orderProcessingStatus,
  }
  return <CreateOfferContext.Provider value={value}>{children}</CreateOfferContext.Provider>
}

export default CreateOfferProvider
