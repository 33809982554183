import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import { DrawerMessenger, RightToolbar, ActivityDrawer, InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { WithChildren } from '../helpers'
import { ThemeModeProvider } from '../partials/layout/theme-mode/ThemeModeProvider'
import OfferFilterComponent from '../partials/widgets/crypto/OfferFilterComponent'
import { HeaderHeroBanner } from '../partials/custom/HeaderHeroBanner'

const MasterLayout: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                  <div
                className={clsx(
                  'd-flex flex-column-fluid align-items-start py-10 ',
                  classes.contentContainer.join(' ')
                )}
                id='kt_post'
              >       <AsideDefault />
               <Content>
                
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

           {/* begin:: Drawers */}
           <ActivityDrawer />
        {/*  <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
