/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../helpers'

const FundTransferApproveSection = ({isBuyer, fiatTransfer, status, type}: any) => {
  return (
    <div className='timeline-item'>
      <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px'>
        <div className='symbol-label bg-light'>
          <KTSVG
            path='/media/icons/duotune/art/art005.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
        </div>
      </div>

      <div className='timeline-content mb-10 mt-n1'>
        <div className='pe-3 mb-5'>
          <div className='fs-5 fw-semibold mb-2'>Transfer Funds</div>
        </div>

        <div className='overflow-auto pb-5'>
          <div className='notice d-flex bg-light-info rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6'>
            <KTSVG
              path='/media/icons/duotune/coding/cod004.svg'
              className='svg-icon-2tx svg-icon-primary me-4'
            />
            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
              <div className='mb-3 mb-md-0 fw-semibold'>
                <h4 className='text-gray-800 fw-bold'>Important!</h4>
                <div className='fs-6 text-gray-600 pe-7'>
                  Please note that transactions made are irreversable. Confirm your transaction once
                  you complete the transfer of funds to seller account.
                </div>
              </div>

              <div className='pe-3 mb-5'>
                {status === 'FIAT_TRANSFERRED' || status === 'COMPLTED' ? (
                  <>
                    <button
                      onClick={() => {}}
                      disabled={true}
                      className='btn btn-primary px-6 align-self-center text-nowrap'
                    >
                      Fiat Trasformed
                    </button>
                  </>
                ) : (
                  <button
                    onClick={fiatTransfer}
                    disabled={status === 'INITIATED' && isBuyer ? false : true}
                    className='btn btn-primary px-6 align-self-center text-nowrap'
                  >
                    Confirm Fund Transfer
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className='pe-3 mb-5'>
          <div className='fs-8 fw-semibold mb-2 text-danger'>
            *Confirm transfer of funds within 30:00 mins
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {FundTransferApproveSection}
