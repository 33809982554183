import clsx from 'clsx'
import _ from 'lodash'
import {Fragment, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl, KTSVG, KTCard} from '../../../helpers'
import {FIAT_ENUM, Sell, TOKEN_ENUM} from 'p2p-abi-lib'
import {RiExchangeFundsFill} from 'react-icons/ri'
import {BsArrowsCollapse} from 'react-icons/bs'
import {
  bigIntConverter,
  decimalConverter,
} from '../../../../app/modules/crypto/helpers/bigintConvertoer'
import {OfferTypeEnum} from './OfferFilterComponent'
import DecimalValue from './DecimalValue'
import {setMatchOrder} from '../../../../app/modules/crypto/helpers/setMatchOrder'
import {toBaseUnit} from '../../../../app/modules/crypto/helpers/convertors'
import {CryptoAccountContext} from '../../../../app/modules/crypto/provider/CryptoAccountProvider'

type CryptoOfferType = {
  cryptoType: TOKEN_ENUM
  profileImage: any
  tokenAddress: any
  totalValue: any
  collateralValue: any
  owner: any
  ownerName: any
  fiat: FIAT_ENUM
  crypto: any
  price: any
  minSellValue?: any
  maxSellValue?: any
  minBuyValue?: any
  maxBuyValue?: any
  position: any
  expiry: any
  notes: any
  offerType?: any
  onOfferSelected: any
  currentSelection: any
  setcurrentSelection: any
}

type Props = CryptoOfferType

export const OfferRow = ({
  currentSelection,
  setcurrentSelection,
  onOfferSelected,
  offerType,
  ...props
}: Props) => {
  const {isWalletConnected, walletAccount, connectWallet}: any = useContext(CryptoAccountContext)
  useEffect(() => {}, [currentSelection])
  const [_paymentMethod, set_paymentMethod] = useState([])
  useEffect(() => {
    if (props?.notes) {
      try {
        const {offerNotes, paymentMethod} = JSON.parse(props?.notes)

        const _pMethods=JSON.parse(paymentMethod)

        set_paymentMethod(_pMethods)
      } catch (error) {}
    }
  }, [props])

  return (
    <Fragment>
      {walletAccount !== props?.owner && props?.totalValue !== '0' && (
        <tr
          className={`my-0  rounded ${
            currentSelection === props?.tokenAddress + '_' + props?.position ? 'bg-light ' : '    '
          }`}
          style={{width: '100%'}}
        >
          <td className='min-w-100px px-4 '>
            <div className='d-flex align-items-center '>
              <div className='symbol symbol-25px me-5'>
                <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                  {_.truncate(props?.owner, {
                    length: 8,
                    omission: '***',
                  })}
                </a>
              </div>
            </div>
          </td>
          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              {props?.price}{' '}
              <span className='text-muted fw-semibold text-muted  fs-7'> {props?.fiat} </span>
            </a>
            {/*   <span className='text-muted fw-semibold text-muted d-block fs-7'>{props?.fiat}</span> */}
          </td>

          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              {offerType === OfferTypeEnum.BUY ? (
                <DecimalValue value={props?.minSellValue} />
              ) : (
                <DecimalValue value={props?.minBuyValue} />
              )}{' '}
              -{' '}
              {offerType === OfferTypeEnum.BUY ? (
                <DecimalValue value={props?.maxSellValue} />
              ) : (
                <DecimalValue value={props?.maxBuyValue} />
              )}
              <span className='text-muted fw-semibold text-muted  fs-7'> {props?.cryptoType}</span>
            </a>
          </td>
          <td className='min-w-100px'>
            <DecimalValue value={props?.totalValue} />
            <span className='text-muted fw-semibold text-muted  fs-7'> {props?.cryptoType}</span>
          </td>

          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              <DecimalValue value={props?.collateralValue} />{' '}
            </a>
          </td>
          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              <span className='text-muted fw-semibold text-muted  fs-7'>
                {_paymentMethod.map((payment: any) => {
                  return <span className="badge badge-light mx-1 p-2 text-capitalize">{payment}</span>
                })}
              </span>
            </a>
          </td>
          <td>
            <div className='rating'>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-8'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-8'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-8'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-8'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-8'></i>
              </div>
            </div>
            <span className='text-muted fw-semibold d-block fs-10'>{343} Orders</span>
            <span className='text-muted fw-semibold d-block fs-10'>94 % Complition</span>
          </td>
          {walletAccount !== props?.owner &&
          currentSelection !== props?.tokenAddress + '_' + props?.position ? (
            <td className='min-w-100px '>
              <div
                className='card-toolbar bg-primary-light'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
              >
                <button
                  onClick={(e: any) => onOfferSelected(props?.tokenAddress + '_' + props?.position)}
                  className={`${
                    offerType === OfferTypeEnum.BUY
                      ? 'bg-success text-white'
                      : 'bg-danger text-white'
                  } btn btn-sm btn-light-primary`}
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_invite_friends'
                >
                  <RiExchangeFundsFill size={18} color='white' />
                  &nbsp;{offerType}
                </button>
              </div>
            </td>
          ) : (
            <td></td>
          )}
        </tr>
      )}
      {currentSelection === props?.tokenAddress + '_' + props?.position && (
        <tr>
          <td colSpan={10}>
            <OfferAction
              offerType={offerType}
              {...props}
              currentSelection={currentSelection}
              setcurrentSelection={setcurrentSelection}
              onOfferSelected={onOfferSelected}
            />
          </td>
        </tr>
      )}
      {walletAccount === props?.owner && props?.totalValue !== '0' && (
        <tr className={``} style={{opacity: 0.4}} title='You Cant Match Your Own Ad'>
          <td className='min-w-150px' title='You Cant Match Your Own Ad'>
            <div className='d-flex align-items-center' title='You Cant Match Your Own Ad'>
              <div className='symbol symbol-45px me-5'>
                <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                  {_.truncate(props?.owner, {
                    length: 22,
                    omission: '***',
                  })}
                </a>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {_.truncate(props?.owner, {
                    length: 10,
                    omission: '***',
                  })}
                </span>
              </div>
            </div>
          </td>
          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              {props?.price}{' '}
              <span className='text-muted fw-semibold text-muted  fs-7'> {props?.fiat} </span>
            </a>
            {/*   <span className='text-muted fw-semibold text-muted d-block fs-7'>{props?.fiat}</span> */}
          </td>
          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              {offerType === OfferTypeEnum.BUY ? (
                <DecimalValue value={props?.minSellValue} />
              ) : (
                <DecimalValue value={props?.minBuyValue} />
              )}{' '}
              -{' '}
              {offerType === OfferTypeEnum.BUY ? (
                <DecimalValue value={props?.maxSellValue} />
              ) : (
                <DecimalValue value={props?.maxBuyValue} />
              )}
              <span className='text-muted fw-semibold text-muted  fs-7'> {props?.cryptoType}</span>
            </a>
          </td>
          <td className='min-w-100px'>
            <DecimalValue value={props?.totalValue} />
            <span className='text-muted fw-semibold text-muted  fs-7'> {props?.cryptoType}</span>
          </td>
          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              <DecimalValue value={props?.collateralValue} />{' '}
            </a>
          </td>
          <td className='min-w-100px'>
            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
              <span className='text-muted fw-semibold text-muted  fs-7'>
                {' '}
                {_paymentMethod.map((payment: any) => {
                  return payment + ' ,'
                })}
              </span>
            </a>
          </td>
          <td>
            <div className='rating'>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-7'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-7'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-7'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-7'></i>
              </div>
              <div className='rating-label me-2 checked'>
                <i className='bi bi-star-fill fs-7'></i>
              </div>
            </div>
            <span className='text-muted fw-semibold d-block fs-10'>{343} Orders</span>
            <span className='text-muted fw-semibold d-block fs-10'>94 % Complition</span>
          </td>{' '}
          <td className='min-w-100px bg-gray-100 '>
            {walletAccount !== props?.owner &&
            currentSelection !== props?.tokenAddress + '_' + props?.position && (
              <div className='card-toolbar bg-primary-light'>
                <button
                  onClick={(e: any) => onOfferSelected(props?.tokenAddress + '_' + props?.position)}
                  className={`${
                    offerType === OfferTypeEnum.BUY
                      ? 'bg-success text-white'
                      : 'bg-danger text-white'
                  } btn btn-sm btn-light-primary`}
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_invite_friends'
                >
                  <RiExchangeFundsFill size={18} color='white' />
                  &nbsp;{offerType}
                </button>
              </div>
            )} {walletAccount !== props?.owner &&
              (currentSelection === props?.tokenAddress + '_' + props?.position) && (
                <div className='card-toolbar bg-primary-light'>
                <button
                  onClick={(e: any) => onOfferSelected(props?.tokenAddress + '_' + props?.position)}
                  className={`${
                    offerType === OfferTypeEnum.BUY
                      ? 'bg-success text-white'
                      : 'bg-danger text-white'
                  } btn btn-sm btn-light-primary`}
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_invite_friends'
                >
                  <RiExchangeFundsFill size={18} color='white' />
                  &nbsp;{offerType}
                </button>
              </div>
            )}
          </td>
        </tr>
      )}
    </Fragment>
  )
}

const OfferAction = (props: any) => {
  const [minToken, setminToken] = useState(0)
  const [maxToken, setmaxToken] = useState(0)
  const [fiatAmount, setfiatAmount] = useState<any>(0)
  const [crytoAmount, setCryptoAmount] = useState<any>(0)
  const [showError, setshowError] = useState('')
  const navigate = useNavigate()
  const [canMatch, setcanMatch] = useState(true)
  const [orderMatchStatus, setorderMatchStatus] = useState<any>({
    initiated: false,
    message: '',
  })

  useEffect(() => {
    setshowError('')
    if (props?.offerType === OfferTypeEnum.BUY) {
      setminToken(decimalConverter(props?.minSellValue))
      setmaxToken(decimalConverter(props?.maxSellValue))
      setCryptoAmount(decimalConverter(props?.minSellValue))
      setfiatAmount(decimalConverter(props?.minSellValue) * props?.price)
    }
    if (props?.offerType === OfferTypeEnum.SELL) {
      setminToken(decimalConverter(props?.minBuyValue))
      setmaxToken(decimalConverter(props?.maxBuyValue))
      setCryptoAmount(decimalConverter(props?.minBuyValue))
      setfiatAmount(decimalConverter(props?.minBuyValue) * props?.price)
    }
  }, [])
  useEffect(() => {}, [orderMatchStatus])

  async function onMatchTrade(tokenName: TOKEN_ENUM, fiat: FIAT_ENUM, position: any, value: any) {
    setshowError('')
    setorderMatchStatus({
      initiated: true,
      message: ' creating order ....',
    })

    const _result: any = await setMatchOrder(props?.offerType, tokenName, fiat, position, value)
    await _result

    if (await _result?.data?.order) {
      setorderMatchStatus({
        initiated: false,
        message: 'Offer Matched ',
      })
      setshowError('')

      /*navigate(
        `/profile/` +
          `${props?.owner}/${props?.tokenAddress}/${props?.position}/${props?.offerType}/${fiat}/${fiatAmount}/${props?.cryptoType}/${crytoAmount}`,
        {replace: true}
      ) */
      navigate(`/profile/matched`, {replace: true})
    } else {
      setorderMatchStatus({
        initiated: false,
        message: 'Matche Order Failed',
      })
      setshowError('Matche Order Failed')
    }
  }
  function onCryptoVolume(data: any) {
    setcanMatch(true)
    setshowError('')
    const _cryptoVal = data
    if (_cryptoVal < minToken) {
      setcanMatch(false)
      setshowError('You have to pay  minimum of ' + minToken + '  ' + props?.cryptoType)
    }
    if (_cryptoVal > maxToken) {
      setcanMatch(false)
      setshowError('You can buy only for maximum of ' + maxToken + '  ' + props?.cryptoType)
    }
    setCryptoAmount(_cryptoVal)
    setfiatAmount(_cryptoVal * props?.price)
  }

  function onFiatAmountChange(data: any) {
    setshowError('')
    setcanMatch(true)
    const _cryptoVal = data / props?.price

    if (_cryptoVal < minToken) {
      setcanMatch(false)
      setshowError(
        'You have to buy  for minimum of ' +
          minToken +
          '  ' +
          props?.cryptoType +
          ' for ' +
          minToken * props?.price +
          ' ' +
          props?.fiat
      )
    } else if (_cryptoVal > maxToken) {
      setcanMatch(false)
      setshowError(
        'You can only buy for maximun of ' +
          maxToken +
          '  ' +
          props?.cryptoType +
          ' for ' +
          minToken * props?.price +
          ' ' +
          props?.fiat
      )
    } else {
      setcanMatch(true)
    }
    setfiatAmount(data)
    setCryptoAmount(data / props?.price)
  }
  return (
    <div className='d-flex w-100 py-4 flex-column bg-gray-100 ' style={{marginTop: -12}}>
      {' '}
      <div className=' d-flex flex-row flex-grow-1'>
        <div className=''>
          <label className='form-label  fs-8 fw-semibold px-4'>I will pay </label>
          <input
            type='text'
            style={{borderStyle: 'none'}}
            className='bg-white  fs-8 border-none p-3'
            placeholder={props?.fiat}
            value={fiatAmount}
            onChange={(e) => onFiatAmountChange(e.target.value)}
          />
          <label className='form-labelfs-8 fw-semibold px-2 mx-2'>{props?.fiat} </label>{' '}
        </div>
        <div className='flex-grow-1'>
          <label className='form-label fs-8 fw-semibold px-4 mx-4'>I will recieve </label>
          <input
            type='text'
            style={{borderStyle: 'none'}}
            className='bg-white  border-none p-3 fs-8'
            placeholder={props?.collatrlaV}
            value={crytoAmount}
            onChange={(e) => onCryptoVolume(e.target.value)}
          />
          <label className='form-label fs-8 fw-semibold px-2 mx-2'>{props?.cryptoType} </label>{' '}
        </div>

        <div className=' mx-5'>
          {orderMatchStatus.initiated === true ? (
            <div
              className={`   px-5  `}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <span className='indicator-progress' style={{display: 'block', paddingTop: 10}}>
                {String(orderMatchStatus?.message).toUpperCase()}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            <div className='mx-5'>
              <button
                disabled={!canMatch}
                className={clsx('btn btn-sm bg-warning  text-white')}
                data-kt-menu-dismiss='true'
                onClick={(e: any) => {
                  onMatchTrade(props?.cryptoType, props?.fiat, props?.position, crytoAmount)
                }}
              >
                Match
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='d-flex  flex-row flex-grow-1 align-items-end '>
        {' '}
        <span className='text-right  text-danger fs-8 capitalize '> {showError}</span>
      </div>
    </div>
  )
}
