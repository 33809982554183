/* eslint-disable jsx-a11y/anchor-is-valid */
import {id} from 'ethers/lib/utils'
import {Account} from 'p2p-abi-lib'
import React, {useContext, useEffect, useState} from 'react'
import {CryptoAccountContext} from '../../../../app/modules/crypto/provider/CryptoAccountProvider'
import {KTSVG} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'
import {client} from '../../../../app/modules/chat/client'
import {LoginChatRoom} from '../../../../app/modules/chat/LoginChatRoom'
function DrawerMessenger() {
  
 
  return (
    <div
      id='kt_drawer_chat'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
     
    
    </div>
  )
}

export {DrawerMessenger}
