/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {LoginChatRoom} from '../../../../app/modules/chat/LoginChatRoom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const ChatSection = ({chatAccount,onChatOpen}: any) => {
  useEffect(() => {
    localStorage.setItem('chatWithUser', chatAccount)
  }, [])
  return (
    <div className='timeline-item'>
      <div className='timeline-line w-40px'></div>
      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
        <div className='symbol-label bg-light'>
          <KTSVG
            path='/media/icons/duotune/communication/com003.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
        </div>
      </div>

      <div className='timeline-content mb-10 mt-n1  '>
        <div className='pe-3 mb-5'>
          <div className='fs-5 fw-bold  mb-2 '>Connect with {chatAccount}</div>
          <div className='d-flex align-items-center mt-1 fs-6'>
            <div className='text-muted me-2 fs-7'>Offline</div>
          </div>
        </div>

        <div className='overflow-auto   d-flex flex-column align-items-end '>
          <div className='d-flex align-items-center'>
            <div className='flex-1 my-4'>
              <LoginChatRoom  onChatOpen={onChatOpen}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChatSection}
