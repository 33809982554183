import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const FinalSellerApproveSection = ({isSeller, confirmPayment, status}: any) => {
  return (
    <div className='timeline-item'>
      <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px'>
        <div className='symbol-label bg-light'>
          <KTSVG
            path='/media/icons/duotune/communication/com009.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
        </div>
      </div>

      <div className='timeline-content mb-10 mt-n2'>
        <div className='overflow-auto pe-3'>
          <div className='fs-5 fw-semibold mb-2'>
            {status === 'INITIATED' && <>Awaitng for FIAT Transfer</>}
            {status === 'FIAT_TRANSFERRED' && <>Awaiting Seller Payment Confirmation</>}
            {status === 'COMPLETED' && <> Seller Confirmed Payment & Token Transfered</>}
          </div>

          <div className='d-flex align-items-end  '>
            <div className='text-muted me-2 fs-7'>
              Transfer of crypto happens once the seller confirms the receival of funds. Please do
              wait until xx minutes and escrow will automaticaly transfer funds to your wallet.
              Confirm the receipt of crypto in your wallet. yet
            </div>

            <div className='pe-3 mb-5'>
              {status === 'COMPLETED' ? (
                <button
                  onClick={() => {}}
                  disabled={status === 'COMPLETED'}
                  className='btn btn-green px-6 align-self-center text-nowrap'
                >
                  Token Transferred
                </button>
              ) : (
                <button
                  onClick={confirmPayment}
                  disabled={status === 'FIAT_TRANSFERRED' && isSeller ? false : true}
                  className='btn btn-primary px-6 align-self-center text-nowrap'
                >
                  Approve Token Transfer                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {FinalSellerApproveSection}
