import React from 'react'

type Props = {}

export const HeaderHeroBanner = (props: Props) => {
  return (
    <div className="container ">
    <div className="row">
        <div className="col-lg-12 text-center text-white" style={{ paddingTop:15}}>
      <div className="text-center" style={{ fontSize: 22, fontWeight: "bold", textAlign: "center" , textTransform:"uppercase"}}>Buy and Sell Crypto safely and easily on P2P</div>
      <div className="text-center" style={{ fontSize: 14, textAlign: "center", paddingTop:15}}>Find the best offer below and buy and sell Crypto with Your Preferred Payment Methods today. 
      </div>
        </div></div>
    </div>
  )
}