import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Market Place' to='/dashboard' />
      {/*       <MenuItem title='Create Offer' to='/' />{' '} */}
      <MenuItem title='My Ads' to='/profile/myads' />
      <MenuItem title='Matched Orders' to='/profile/matched' />
     
    </>
  )
}
