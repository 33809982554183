import {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'

import {OfferTypeEnum} from '../../../../../_metronic/partials/widgets/crypto/OfferFilterComponent'
import CreateBuyOfferContainer from './CreateBuyOfferContainer'
import CreateSellOfferContainer from './CreateSellOfferContainer'
import CreateOfferProvider from './CreateOfferProvider'
type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const OfferTypeButton = ({offerType, onOfferTypeChange}: any) => {
  useEffect(() => {}, [offerType])

  return (
    <div
      className='d-flex flex-row   bg-gray-100   cursor-pointer bg-success-light   '
      style={{borderRadius: 5}}
    >
      <div
        className={` flex-grow-1 p-2 ${
          offerType === OfferTypeEnum.BUY ? 'bg-success text-white' : ''
        }     cursor-pointer text-center`}
        style={{fontSize: 21}}
        onClick={(e: any) => onOfferTypeChange(OfferTypeEnum.BUY)}
      >
        {OfferTypeEnum.BUY}
      </div>
      <div
        className={` flex-grow-1 p-2 ${
          offerType === OfferTypeEnum.SELL ? 'bg-danger text-white' : ''
        }    cursor-pointer text-center`}
        style={{fontSize: 21}}
        onClick={(e: any) => onOfferTypeChange(OfferTypeEnum.SELL)}
      >
        {OfferTypeEnum.SELL}
      </div>{' '}
    </div>
  )
}

const CreateOfferModal = ({show, handleClose}: any) => {
  const [offerType, setofferType] = useState(OfferTypeEnum.BUY)
  function onOfferTypeChange(type: OfferTypeEnum) {
    setofferType(type)
  }
  useEffect(() => {}, [offerType])

  function onModalLoaded() {}
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='false'
      backdrop
    
      dialogClassName='modal-dialog modal-dialog-centered modal-lg   rounded'
      show={show}
      onHide={handleClose}
      onEntered={onModalLoaded}
    >
      <CreateOfferProvider>
        <div
          className=' rounded card '
          style={{
            minHeight: 450,
          }}
        >
          <div className='d-flex flex-grow-1 flex-row'>
            <div className=' flex-grow-1  '>
              <OfferTypeButton offerType={offerType} onOfferTypeChange={onOfferTypeChange} />
            </div>
          </div>
          <div className=' flex-grow-1  '>
            {offerType === OfferTypeEnum.SELL && (
              <CreateSellOfferContainer handleClose={handleClose} />
            )}
            {offerType === OfferTypeEnum.BUY && (
              <CreateBuyOfferContainer handleClose={handleClose} />
            )}
          </div>
        </div>
      </CreateOfferProvider>
    </Modal>,
    modalsRoot
  )
}

export {CreateOfferModal}
