/* eslint-disable jsx-a11y/anchor-is-valid */
import {StepProps} from '../IAppModels'

const Step5 = ({data, updateInput, hasError}: any) => {
  return (
    <>
      {/*begin::Step 5 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className=' '>
          {/*begin::Form Group */}
          <div className='fv-row'>
            <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Notes</span>
            </label>

            <div className='fv-row mb-10 mt-60  '>
              <label className='fs-5 fw-semibold mb-2'>
                Write a message :  <span className='fs-8 fw-semibold mb-2'>{String(data.notes).length} / 30 charecters left</span> 
              </label>
              <textarea
                name='notes'
                className='form-control form-control-lg form-control-solid mb-3'
                rows={4}
                maxLength={30}
                data-kt-element='input'
                placeholder='only 30 characters allowed'
                value={data.notes}
                onChange={(e) => {
                  updateInput({key: 'notes', value: e.target.value})
                }}
              ></textarea>
            </div>
          </div>
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 4 */}
    </>
  )
}

export {Step5}
