import {PageTitle} from '../../../_metronic/layout/core'
import {FcAddRow, FcPlus} from 'react-icons/fc'
import {OffersListsV1} from '../../../_metronic/partials/widgets/crypto/lists/OffersList'
import OfferFilterComponent, {
  FilterOption,
  OfferTypeEnum,
} from '../../../_metronic/partials/widgets/crypto/OfferFilterComponent'

import {useEffect, useState} from 'react'
import {HeaderHeroBanner} from '../../../_metronic/partials/custom/HeaderHeroBanner'
import {createNewAd} from '../../modules/crypto/helpers/createNewAd'

import {fetchOrders} from '../../modules/crypto/helpers/fetchOrders'
import {TOKEN_ENUM, FIAT_ENUM, Sell, getCryptoTokenAddress, Account} from 'p2p-abi-lib'
import {CreateOfferModal} from '../../modules/crypto/component/create-app-stepper/CreateOfferModal'
import {bigIntConverter} from '../../modules/crypto/helpers/bigintConvertoer'

const _initFilters: FilterOption = {
  offerType: OfferTypeEnum.BUY,
  crypto: TOKEN_ENUM.LINK,
  totalValue: '',
  fiat: FIAT_ENUM.INR,
}
const DashboardPage = () => {
  const [offerFilters, setOfferFilters] = useState<FilterOption | any>(_initFilters)
  const [offers, setoffers] = useState<any[]>([])
  const [showCreateSellOfferModal, setshowCreateSellOfferModal] = useState(false)
  const [loading, setloading] = useState(false)
  useEffect(() => {
    /*   test() */
  }, [])

  function onCreateSellOfferModalClose() {
    setOfferFilters({type: 'offerType', value: OfferTypeEnum.SELL})
    _fetchOffers({})
    setshowCreateSellOfferModal(false)
  }

  function onFilterChange(filter: {type: any; value: any}) {
    setOfferFilters((currentFilters: FilterOption) => ({
      ...currentFilters,
      [String(filter.type)]: filter.value,
    }))
  }

  async function _fetchOffers({offerType}: any) {
    setloading(true)
    const _results = await fetchOrders(
      offerType ? {offerType: offerType, ...offerFilters} : offerFilters
    )
    setoffers(await _results?.orders)
    setloading(false)
  }

  function onFilterReset() {
    setOfferFilters(_initFilters)
  }

  useEffect(() => {
    _fetchOffers({})
  }, [offerFilters])

  useEffect(() => {
    console.log(offers)
  }, [offers])

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <HeaderHeroBanner />
        </div>
        <div className='col-xl-12 card'>
          <OfferFilterComponent
            filters={offerFilters}
            onFilterChange={onFilterChange}
            onFilterReset={onFilterReset}
          />
        </div>
        <div className='col-xl-12'>
          {loading ? (
            <div
              className={`card   `}
              style={{
                padding: 0,
                minHeight: 350,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <span className='indicator-progress' style={{display: 'block', paddingTop: 150}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : (
            <OffersListsV1
              className='card-xxl-stretch mb-5 mb-xl-12'
              offers={offers}
              filters={offerFilters}
            />
          )}{' '}
        </div>
        <div className=' pt-5'>
          <div className='notice d-flex bg-light-success rounded border-success border border-dashed min-w-lg-600px flex-shrink-0 p-6'>
            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
              <div className='mb-3 mb-md-0 fw-semibold'>
                <span className='text-gray-800 fw-bold fs-7'>
                  Didn’t find any matching offers ?{' '}
                </span>
              </div>

              <button
                className='btn btn-success px-6 align-self-center text-nowrap'
                onClick={() => setshowCreateSellOfferModal(true)}
              >
                New Offer&nbsp; <FcPlus size={24} color='white' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <CreateOfferModal show={showCreateSellOfferModal} handleClose={onCreateSellOfferModalClose} />

      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{' P 2 P Dashboard'}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
