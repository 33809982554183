/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import {StepProps} from '../IAppModels'

const Step4 = ({data, updateInput, hasError}: any) => {

  const [showColInput, setColInput] = useState(false)
  return (
    <>
      {/*begin::Step 4 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className=' '>
          {/*begin::Form Group */}
          <div className='fv-row my-5'>
            <label className='fs-6 fw-bolder text-dark mb-7d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Stake a native coin</span>
            </label>

            {/*begin:checkbox */}
            {/* <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'> */}
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5 my-60'>
              <input
                className='form-check-input'
                type='checkbox'
               
                checked={showColInput}
                onChange={() =>
                  {setColInput(prev=>!prev)}
                }
              />
              <span className='form-check-label'>Do you want the buyer to stake a coin ?</span>
            </label>

            {/*end::checkbox */}
{showColInput===true&&
            <div className='fv-row mb-10 mt-60 w-300px my-5'>
              <label className='fs-5 fw-semibold mb-2'>Collateral  Value</label>

              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='collateral'
                value={data?.collateral}
                onChange={(e) =>
                  { updateInput({key: 'collateral', value: e.target.value})}   
                }
              />
              {!data?.collateral && hasError && (
                <div className='fv-plugins-message-container'>
                  <div
                    data-field='maxOrderValue'
                    data-validator='notEmpty'
                    className='fv-help-block'
                  >
                    Stake value is required
                  </div>
                </div>
              )}
            </div>}
          </div>
            
          {/*end::Form Group */}
        </div> 
      </div> 
      {/*end::Step 4 */}
    </>
  )
}

export {Step4}