import clsx from 'clsx'
import _, {isEmpty} from 'lodash'
import {useContext, useEffect, useState} from 'react'
import {UserInfoModel} from '../../../_metronic/helpers'
import {CryptoAccountContext} from '../crypto/provider/CryptoAccountProvider'
import useWebSocket, {ReadyState} from 'react-use-websocket'
import moment from 'moment'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const lvie_url = 'https://cryptop2pchat.herokuapp.com/'
const lvie_ws = 'wss://cryptop2pchat.herokuapp.com/ws'
export const active_Url = lvie_url
export const acrtive_ws = lvie_ws

function ChatContainer({chatWith}: any) {
  const {walletAccount}: any = useContext(CryptoAccountContext)
  const {mode} = useThemeMode()
  const [messages, setmessages] = useState<any[]>([])

  const [newMessage, setnewMessage] = useState('')

  const [userInfos] = useState<UserInfoModel[]>([])

  var authUser: any = localStorage.getItem('loggedInUser')
  var loggedInUserToken: any = localStorage.getItem('loggedInUserToken')
  const [socketUrl, setSocketUrl] = useState(
    acrtive_ws + '?Authorization=' + localStorage.getItem('loggedInUserToken')
  )
  const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
  })

  async function fetchMessages() {
    if (authUser && loggedInUserToken) {
      fetch(active_Url + 'messages', {
        method: 'get',
        headers: new Headers({
          Authorization: await loggedInUserToken,
        }),
      })
        .then(async (res) => {
          if (res?.status === 401) {
          }
          console.log(res?.status)
          var body = await res.json()

          setmessages(body[chatWith])
          /* setUpWebsocket() */
        })
        .catch((error) => {
          console.log('res', error)
        })
    }
  }


  useEffect(() => {
    fetchMessages()
  }, [])
  useEffect(() => {}, [messages])
  useEffect(() => {
    if (lastMessage && lastMessage?.data) {
      var incomingMessage = JSON.parse(lastMessage?.data)
      setmessages([...messages, incomingMessage])
      console.log('lastMessage', lastMessage?.data)
    }
  }, [lastMessage, setmessages])

  function onSendMessageEnter(e: any) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      if (!isEmpty(newMessage)) {
        var send = {To: chatWith, Text: newMessage}
        sendMessage(JSON.stringify(send), true)
        setnewMessage('')
      }
    }
  }
  function onSendMessageButton(e: any) {
    e.preventDefault()
    if (!isEmpty(newMessage)) {
      var send = {To: chatWith, Text: newMessage}
      sendMessage(JSON.stringify(send), true)
      setnewMessage('')
    }
  }
  return (
    <div className='' style={{display: 'flex', flexDirection: 'column'}}>
      <div
        className=' '
        style={{
          backgroundColor: mode === 'dark' ? '#000' : '#fff',
          flexGrow: 1,
          borderBottom: 1,
          position: 'absolute',
          marginTop: -50,
          marginLeft: -24,
          padding: 15,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          zIndex: 15000,
          borderBottomColor: mode === 'dark' ? '#fff' : '#000',
        }}
      >
        <div className=' ' style={{flexGrow: 1, color: mode === 'dark' ? '#fff' : '#000'}}>
          Chat With :{' '}
          <a
            href='#'
            className='fs-8 fw-bold  '
            style={{
              paddingLeft: 15,
            }}
          >
            {_.truncate(chatWith, {
              length: 18,
              omission: ' ***',
            })}
          </a>
        </div>
      </div>
      <div
        className=' '
        style={{
          marginBottom: 100,
          borderTop: 1,
          borderColor: mode === 'dark' ? '#fff' : '#000',

          flexGrow: 1,
        }}
      >
        <div
          className={clsx('scroll-y')}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
        >
          {messages?.map((message: any, index: any) => {
            message.type = message.From === authUser ? 'out' : 'in'
            const state = message.type === 'in' ? 'info' : 'primary'
            const templateAttr = {}
            if (message.template) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-${message.type}`,
              })
            }
            const contentClass = `d-flex justify-content-${
              message.type === 'in' ? 'start' : 'end'
            } mb-5`
            return (
              <div
                key={`message${index}`}
                className={clsx('d-flex flex-column', contentClass, 'mb-5', {
                  'd-none': message.template,
                })}
                /*  {...templateAttr} */
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                >
                  <div className='d-flex align-items-center mb-2 flex-row'>
                    {message.type === 'in' ? (
                      <>
                        <div className=' '>
                          <a
                            href='#'
                            className='fs-7 fw-bold text-gray-900 text-hover-primary me-1'
                          >
                            {' '}
                            {_.truncate(message.From, {
                              length: 8,
                              omission: '**',
                            })}
                          </a>
                        </div>
                        <div className='symbol  symbol-35px symbol-circle'>
                          <span className='text-muted    fs-8'>
                            {moment(message.CreatedAt).fromNow()}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=' '>
                          <a
                            href='#'
                            className='fs-7 fw-bold text-gray-900 text-hover-primary me-1'
                          >
                            You
                          </a>
                        </div>
                        <div className='symbol  symbol-35px symbol-circle'>
                          <span className='text-muted     fs-8'>
                            {moment(message.CreatedAt).fromNow()}
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'p-2 rounded',
                      `bg-light-${state}`,
                      'text-dark  ',
                      `text-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{__html: message.Text}}
                  ></div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div
        style={{
          padding:5,
          borderRadius:15,
          position: 'fixed',
          background: '#000',
          bottom: 10,
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <textarea
            className='form-control 
            form-control-flush '
            style={{
              flexGrow: 1,
            }}
            rows={1}
            data-kt-element='input'
            placeholder='Type a message'
            value={newMessage}
            onChange={(e) => setnewMessage(e.target.value)}
            onKeyDown={onSendMessageEnter}
          ></textarea>
        </div>
        <div style={{ flexGrow: 1}}>
          <button
            className='btn btn-primary '
            type='button'
            data-kt-element='send'
            onClick={onSendMessageButton}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChatContainer
