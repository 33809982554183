import React, {useEffect, useState} from 'react'
import {connectCryptoWallet} from '../helpers/connectCryptoWallet'

type Props = {}

export const CryptoAccountContext = React.createContext<
  | {
      isWalletConnected: boolean
      walletAccount: any
      connectWallet: () => {} | void
    }
  | undefined
>(undefined)

const CryptoAccountProvider = ({children, ...props}: any) => {
  const [isWalletConnected, setIsWalletConnected] = useState(false)
  const [walletAccount, setwalletAccount] = useState<any>(undefined)

  useEffect(() => {
    if (walletAccount) {
      setIsWalletConnected(true)
    } else {
      connectWallet()
      setIsWalletConnected(false)
    }
  }, [])

  useEffect(() => {
    if (walletAccount) {
      setIsWalletConnected(true)
    } else {
      setIsWalletConnected(false)
    }
  }, [walletAccount])

  async function connectWallet() {
    const _account = await connectCryptoWallet()
    await _account
    setwalletAccount(_account)
    return _account
  }

  const value: any = {
    isWalletConnected,
    walletAccount,
    connectWallet,
  }
  return <CryptoAccountContext.Provider value={value}>{children}</CryptoAccountContext.Provider>
}

export default CryptoAccountProvider
