import {Buy, FIAT_ENUM, Sell, TOKEN_ENUM} from 'p2p-abi-lib'
import {convFiatTokenAddress} from 'p2p-abi-lib/lib/helpers/formatFiatAndToken'

export async function getBuyMatchedOrder({address, index, type}: any) {
  const _orderDetail = await Buy.currentBuyOrder({
    ACCOUNT: address,
    INDEX: index,
  })
  await _orderDetail
  return _orderDetail
}

export async function getSellMatchedOrder({address, index, type}: any) {
  const _orderDetail = await Sell.currentSellOrder({
    ACCOUNT: address,
    INDEX: index,
  })
  await _orderDetail
  return _orderDetail
}
