import {Buy, Sell} from 'p2p-abi-lib'
import {
  FilterOption,
  OfferTypeEnum,
} from '../../../../_metronic/partials/widgets/crypto/OfferFilterComponent'

export async function fetchOrders(filters: FilterOption) {
  if (filters?.offerType === OfferTypeEnum.BUY) {
    var _result: any = await Sell.sellOrders({
      FIAT: filters.fiat,
      TOKEN_NAME: filters.crypto,
      CURSOR: 1,
      LIMIT: 5,
    })

    await _result

    if (await _result?.data) {
      return await _result?.data
    } else {
      return []
    }
  } else {
    const _result: any = await Buy.buyOrders({
      FIAT: filters.fiat,
      TOKEN_NAME: filters.crypto,
      CURSOR: 1,
      LIMIT: 5,
    })
    await _result

    if (await _result?.data) {
      return await _result?.data
    } else {
      return []
    }
  }
}
