import clsx from 'clsx'
import {FIAT_ENUM, TOKEN_ENUM} from 'p2p-abi-lib/lib/constants/tokens'
import {FcClearFilters} from 'react-icons/fc'
import React, {useEffect, useState} from 'react'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  offerType: any
}

const offerStyle = {
  backgourColor: '#F1416C',
}
const sellerStyle = {
  backgourColor: '#F1416C',
}

const _offerType = {
  padding: 10,
  borderRadius: 5,
  backgroundColor: '#52c386',
  color: 'white',
}

const OfferTypeButton = ({active, onOfferTypeChange}: any) => {
  const {mode} = useThemeMode()

  useEffect(() => {
    console.log(active)
  }, [active])

  return (
    <div
      className='d-flex flex-row px-4 bg-gray-100 p-2 cursor-pointer bg-success-light '
      style={{borderRadius: 5}}
    >
      <div
        className={`${
          active === OfferTypeEnum.BUY ? 'bg-success text-white' : ''
        }  p-3 rounded  cursor-pointer`}
        style={{}}
        onClick={(e: any) => onOfferTypeChange(OfferTypeEnum.BUY)}
      >
        Sellers
        {/*  {OfferTypeEnum.BUY} */}
      </div>
      <div
        className={`${
          active === OfferTypeEnum.SELL ? 'bg-danger text-white' : ''
        }  p-3 rounded  cursor-pointer`}
        onClick={(e: any) => onOfferTypeChange(OfferTypeEnum.SELL)}
      >
        Buyers
      </div>{' '}
    </div>
  )
}

const OfferCyrpotType = ({active, onTOKEN_ENUMChange}: any) => {
  useEffect(() => {}, [active])

  return (
    <div className='d-flex flex-row px-4 bg-gray-100 p-2 cursor-pointer rounded' style={{}}>
      <div
        className={`${
          active === TOKEN_ENUM.LINK ? 'bg-primary text-white' : ''
        }  p-3 rounded rounded  cursor-pointer`}
        onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.LINK)}
      >
        {TOKEN_ENUM.LINK}
      </div>
      <div
        className={`${
          active === TOKEN_ENUM.ETH ? 'bg-primary text-white' : ''
        }  p-3 rounded rounded  cursor-pointer`}
        onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.ETH)}
      >
        {TOKEN_ENUM.ETH}
      </div>
      <div
        className={`${
          active === TOKEN_ENUM.USDT ? 'bg-primary text-white' : ''
        }  p-3 rounded  cursor-pointer`}
        onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.USDT)}
      >
        {TOKEN_ENUM.USDT}
      </div>
      <div
        className={`${
          active === TOKEN_ENUM.BUSDT ? 'bg-primary text-white' : ''
        }  p-3 rounded  cursor-pointer`}
        onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.BUSDT)}
      >
        {TOKEN_ENUM.BUSDT}
      </div>
      <div
        className={`${
          active === TOKEN_ENUM.BTC ? 'bg-primary text-white' : ''
        }  p-3 rounded  cursor-pointer`}
        onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.BTC)}
      >
        {TOKEN_ENUM.BTC}
      </div>
      {/*   <div className={`${active === TOKEN_ENUM.ADA ? "bg-primary text-white" : ""}  p-3 rounded  cursor-pointer`} onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.ADA)} >{TOKEN_ENUM.ADA}</div>
        <div className={`${active === TOKEN_ENUM.MATIC ? "bg-primary text-white" : ""}  p-3 rounded  cursor-pointer`} onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.MATIC)}>{TOKEN_ENUM.MATIC}</div>
        */}{' '}
      <div
        className={`${
          active === TOKEN_ENUM.WRX ? 'bg-primary text-white' : ''
        }  p-3 rounded  cursor-pointer`}
        onClick={(e: any) => onTOKEN_ENUMChange(TOKEN_ENUM.WRX)}
      >
        {TOKEN_ENUM.WRX}
      </div>{' '}
    </div>
  )
}

export enum OfferTypeEnum {
  BUY = 'BUY',
  SELL = 'SELL',
}

export interface FilterOption {
  offerType?: OfferTypeEnum
  fiat: FIAT_ENUM
  crypto: TOKEN_ENUM
  totalValue?: any
}

export default function OfferFilterComponent({filters, onFilterChange, onFilterReset}: any) {
  const {mode} = useThemeMode()

  useEffect(() => {}, [filters])

  function onOfferTypeChange(type: OfferTypeEnum) {
    onFilterChange({type: 'offerType', value: type})
  }

  function onFiatOptionChange(type: OfferTypeEnum) {
    onFilterChange({type: 'fiat', value: type})
  }

  function onTOKEN_ENUMChange(type: OfferTypeEnum) {
    onFilterChange({type: 'crypto', value: type})
  }

  function onAmountTextChange(type: String) {
    onFilterChange({type: 'totalValue', value: type})
  }
  return (
    <React.Fragment>{ mode === 'light'&&
    <div className='  row  rounded  px-5 py-5      ' style={{}}>
      <div className='col-xs-6 col-sm-2 col-md-2'>
        <div className='d-flex  flex-column'>
          <div className=' my-2'>&nbsp; Active Ads </div>
          <div className='flex-grow-1 my-2'>
            <OfferTypeButton active={filters?.offerType} onOfferTypeChange={onOfferTypeChange} />
          </div>
        </div>
      </div>
      <div className='col-xs-6 col-sm-2 col-md-4 '>
        <div className='d-flex  flex-column'>
          <div className=' my-2'>Crypto Currency</div>
          <div className='flex-grow-1 my-2 rounded'>
            <OfferCyrpotType active={filters?.crypto} onTOKEN_ENUMChange={onTOKEN_ENUMChange} />
          </div>
        </div>
      </div>

      <div className='col-xs-6 col-sm-2 col-md-2'>
        <div className='d-flex  flex-column'>
          <div className=' my-2'>Payment In</div>

          <div className='flex-grow-1 my-2 rounded'>
            <select
              className='form-select form-select-solid py-4'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={filters?.fiat}
              value={filters?.fiat}
              onChange={(e: any) => {
                onFiatOptionChange(e.target.value)
              }}
            >
              <option value={FIAT_ENUM?.INR}>INR</option>
              <option value={FIAT_ENUM?.USD}>USD</option>
              <option value={FIAT_ENUM?.AED}>AED</option>
              <option value={FIAT_ENUM?.SGD}>SGD</option>
            </select>
          </div>
        </div>
      </div>

      <div className='col-xs-6 col-sm-2 col-md-2'>
        <div className='d-flex  flex-column'>
          <div className=' my-2 fs-7 fw-bold'> Value </div>

          <div className='flex-grow-1 my-2 '>
            {' '}
            <input
              type='text'
              style={{borderStyle: 'none'}}
              className='bg-gray-100 border-none p-3 py-4'
              placeholder={filters?.fiat}
              value={filters?.amount}
              onChange={(e) => onAmountTextChange(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='mx-5 col-xs-6 col-sm-1 col-md-1'>
        <button
          type='reset'
          className={clsx('btn btn-sm py-4 me-2 my-12 d-flex flex-row', {})}
          data-kt-menu-dismiss='true'
          onClick={(e: any) => {
            onFilterReset()
          }}
        >
          <span>Clear</span>
           
        </button>
      </div>
    </div>}
    { mode === 'dark'&&
    <div className='  card-body row  rounded  px-5 py-5      ' style={{}}>
      <div className='col-xs-6 col-sm-2 col-md-2'>
        <div className='d-flex  flex-column'>
          <div className=' my-2'>&nbsp; Active Ads </div>
          <div className='flex-grow-1 my-2'>
            <OfferTypeButton active={filters?.offerType} onOfferTypeChange={onOfferTypeChange} />
          </div>
        </div>
      </div>
      <div className='col-xs-6 col-sm-2 col-md-4 '>
        <div className='d-flex  flex-column'>
          <div className=' my-2'>Crypto Currency</div>
          <div className='flex-grow-1 my-2 rounded'>
            <OfferCyrpotType active={filters?.crypto} onTOKEN_ENUMChange={onTOKEN_ENUMChange} />
          </div>
        </div>
      </div>

      <div className='col-xs-6 col-sm-2 col-md-2'>
        <div className='d-flex  flex-column'>
          <div className=' my-2'>Payment In</div>

          <div className='flex-grow-1 my-2 rounded'>
            <select
              className='form-select form-select-solid py-4'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={filters?.fiat}
              value={filters?.fiat}
              onChange={(e: any) => {
                onFiatOptionChange(e.target.value)
              }}
            >
              <option value={FIAT_ENUM?.INR}>INR</option>
              <option value={FIAT_ENUM?.USD}>USD</option>
              <option value={FIAT_ENUM?.AED}>AED</option>
              <option value={FIAT_ENUM?.SGD}>SGD</option>
            </select>
          </div>
        </div>
      </div>

      <div className='col-xs-6 col-sm-2 col-md-2'>
        <div className='d-flex  flex-column'>
          <div className=' my-2 fs-7 fw-bold'> Value </div>

          <div className='flex-grow-1 my-2 '>
            {' '}
            <input
              type='text'
              style={{borderStyle: 'none'}}
              className='bg-gray-100 border-none p-3 py-4'
              placeholder={filters?.fiat}
              value={filters?.amount}
              onChange={(e) => onAmountTextChange(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='mx-5 col-xs-6 col-sm-1 col-md-1'>
        <button
          type='reset'
          className={clsx('btn btn-sm py-4 me-2 my-12 d-flex flex-row', {})}
          data-kt-menu-dismiss='true'
          onClick={(e: any) => {
            onFilterReset()
          }}
        >
          <span>Clear</span>
         
        </button>
      </div>
    </div>}</React.Fragment>
  )
}
