/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {ChatSection} from '../../../content/activity/ChatSection'
import {FinalSellerApproveSection} from '../../../content/activity/FinalSellerApproveSection'
import {FundTransferApproveSection} from '../../../content/activity/FundTransferApproveSection'

type Props = {
  className?: string
  order?: any
  fiatTransfer: (data: any) => {} | void
  confirmPayment: (data: any) => {} | void
  matchedBy: any
  owner: any
  buyer: any
  seller: any
  chatWith: any
  type: any
  walletAccount: any
  onChatOpen: (data: any) => {} | void
}

const DetailSteps: React.FC<Props> = ({
  buyer,
  seller,
  owner,
  type,
  matchedBy,
  walletAccount,
  className,
  order,
  fiatTransfer,
  chatWith,
  confirmPayment,
  onChatOpen,
}) => {
  const [isBuyer, setIsBuyer] = useState(true)

  useEffect(() => {
    setIsBuyer(false)
    if (type === 'SELL' && owner === walletAccount) {
      setIsBuyer(false)
    }
    if (type === 'BUY' && owner === walletAccount) {
      setIsBuyer(true)
    }
  }, [owner, type, matchedBy, walletAccount, chatWith])

  return (
    <div className={`card ${className}`} style={{padding: 0, minHeight: 500}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h1 className='card-title align-items-start flex-column'>
          Order Processing : {order?.status}
        </h1>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body '>
        <div className='timeline'>
          <ChatSection chatAccount={chatWith} onChatOpen={onChatOpen} />
          <FundTransferApproveSection
            isBuyer={buyer === walletAccount}
            fiatTransfer={fiatTransfer}
            status={order?.status}
          />
          <FinalSellerApproveSection
            isSeller={seller === walletAccount}
            confirmPayment={confirmPayment}
            status={order?.status}
          />
        </div>{' '}
      </div>
    </div>
  )
}
export {DetailSteps}
