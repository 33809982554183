import Web3 from 'web3'
 

export async function connectCryptoWallet(url?: any) {
  try {
    const {ethereum}: any = window
    /*  const web3: any = new Web3(Web3.givenProvider || 'http://localhost:8545') */
    const web3: any = new Web3(Web3.givenProvider || 'http://localhost:8545')
    const __account = await web3.eth.requestAccounts()

    if (ethereum) {
    }
    if (__account[0]) {
      return __account[0]
    }
  } catch (error: any) {
    console.log(error)
    return null
  }
}

export async function disconnectWallet(url?: any) {
  try {
    const {ethereum}: any = window
    /*  const web3: any = new Web3(Web3.givenProvider || 'http://localhost:8545') */
    const web3: any = new Web3(Web3.givenProvider || 'http://localhost:8545')
    const __account = await web3.eth.requestAccounts()

    if (ethereum) {
    }
    if (__account[0]) {
      return __account[0]
    }
  } catch (error: any) {
    console.log(error)
    return null
  }
}
