import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useParams } from 'react-router-dom'
import { HeaderDetailBanner } from '../../../_metronic/partials/custom/HeaderDetailBanner'
import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { DetailSteps } from '../../../_metronic/partials/widgets/crypto/details/DetailSteps'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useContext, useEffect, useState } from 'react'
import { CryptoAccountContext } from '../../modules/crypto/provider/CryptoAccountProvider'

import {
  getBuyMatchedOrder,
  getSellMatchedOrder,
} from '../../modules/crypto/helpers/getMatchedOrderDetail'
import { getCryptoTokenName } from 'p2p-abi-lib/lib/helpers/formatFiatAndToken'
import { Buy, Sell } from 'p2p-abi-lib'
import { decimalConverter } from '../../modules/crypto/helpers/bigintConvertoer'
import ChatContainer from '../../modules/chat/ChatContainer'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const DetailPage = ({ owner, matchedby, index, type }: any) => {
  const { isWalletConnected, walletAccount, connectWallet }: any = useContext(CryptoAccountContext)
  const [account, setaccount] = useState('')
  const [buyer, setbuyer] = useState<any>()
  const [seller, setseller] = useState<any>()
  const { mode } = useThemeMode()
  const [chatOpen, setChatOpen] = useState(false)

  useEffect(() => {
    if (!walletAccount) {
      connectWallet()
    } else {
      setaccount(walletAccount)
    }
  }, [walletAccount])
  const [offer, setoffer] = useState<any>()
  useEffect(() => {
    if (owner && matchedby && type) {
      if (type === 'BUY') {
        setbuyer(owner)
        setseller(matchedby)
      }
      if (type === 'SELL') {
        setbuyer(matchedby)
        setseller(owner)
      }
    }
  }, [owner, matchedby, index, type])

  useEffect(() => {
    if (buyer && seller) {
      _getMatchedOrderDetail()
    }
  }, [buyer, seller])

  async function _getMatchedOrderDetail() {
    if (type === 'BUY') {
      const _orderDetail = await getBuyMatchedOrder({
        address: seller,
        index: index,
        type: type,
      })
      await _orderDetail
      console.log('_orderDetail', _orderDetail)
      setoffer(_orderDetail)
    }
    if (type === 'SELL') {
      const _orderDetail = await getSellMatchedOrder({
        address: buyer,
        index: index,
        type: type,
      })
      await _orderDetail

      setoffer(_orderDetail)
    }
  }

  async function cancelTransaction() {
    console.log('type', type)
    console.log('walletAccount', walletAccount)
    console.log('seller', seller)
    console.log('buyer', buyer)
    if (type === 'SELL') {
      if (walletAccount === seller) {
        const _declineSeller = await Sell.declineSeller({
          BUYER_ADDRESS: buyer,
          POSITION: index,
        })

        await _declineSeller
      }
      if (walletAccount === buyer) {
        const _declineBuyer = await Sell.declineBuyer({
          POSITION: index,
        })

        await _declineBuyer
      }
    }
    if (type === 'BUY') {
      if (walletAccount === seller) {
        const _declineSeller = await Buy.declineSeller({
          SELLER_ADDRESS: seller,
          POSITION: index,
        })

        await _declineSeller
      }
      if (walletAccount === buyer) {
        const _declineBuyer = await Buy.declineBuyer({
          SELLER_ADDRESS: seller,
          POSITION: index,
        })

        await _declineBuyer
      }
    }
    await _getMatchedOrderDetail()
  }

  async function fiatTransfer(params: any) {
    if (type === 'SELL') {
      const _fiatTransfer = await Sell.approveBuyer({
        POSITION: index,
      })
      await _fiatTransfer
    }
    if (type === 'BUY') {
      const _fiatTransfer = await Buy.approveBuyer({
        SELLER_ADDRESS: seller,
        POSITION: index,
      })
      await _fiatTransfer
    }
    _getMatchedOrderDetail()
  }

  async function confirmPayment(params: any) {
    if (type === 'SELL') {
      const _confirmPayment = await Sell.approveSeller({
        BUYER_ADDRESS: buyer,
        POSITION: index,
      })
      await _confirmPayment
    }
    if (type === 'BUY') {
      const _confirmPayment = await Buy.approveSeller({
        POSITION: index,
      })
      await _confirmPayment
    }
    _getMatchedOrderDetail()
  }
  /*  if (!offer) {
    return <div>.....loading</div>
  } */
  return (
    <>
      <div className='row gy-5 py-10 gx-xl-8'>
        <div className='col-xl-12'>
          <HeaderDetailBanner
            offerType={type}
            fiat={offer?.fiat}
            cryptoType={getCryptoTokenName(offer?.tokenAddress)}
          />
        </div>
        <div className='col-xl-12'>
          <div className='d-flex  flex-column flex-md-row'>
            <div className='card flex-grow-1  mx-2  my-2'>
              <div className='card-body'>
                <div className='d-flex flex-row py-4'>
                  <div className='symbol symbol-45px me-2 '></div>
                  <div className=' py-2'>
                    <span className='fs-2 fw-bold'>Order Details</span>
                  </div>
                </div>
                <div className='d-flex flex-row'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-6 fw-bold'>{decimalConverter(offer?.buyerAmount)}</div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-400'>
                      {getCryptoTokenName(offer?.tokenAddress)}
                    </div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-6 fw-bold'>{offer?.price}</div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-400'>{offer?.fiat}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card flex-grow-1  mx-2  my-2'>
              <div className='card-body'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='symbol symbol-50px symbol-lg-50px symbol-fixed position-relative'>
                      <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metornic' />
                      <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                    </div>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold me-1 px-4'>
                      {walletAccount === owner ? matchedby : owner}
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      />
                      Last trade 22 mins ago
                    </a>
                  </div>
                  <div className='d-flex  flex-column'>
                    <div className='d-flex flex-row py-1'>
                      <span>Transaction Snapshot</span>
                    </div>
                    <div className='d-flex flex-column  flex-md-row'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold'>{4.5}</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>Ratings</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold'>3333</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>Orders</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold'>130</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>Postive Orders</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold'>3 Min</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>Avg Completion Time</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-6 fw-bold'>1221425</div>
                        </div>

                        <div className='fw-semibold fs-6 text-gray-400'>Volume</div>
                      </div>
                    </div>
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
          <div className='card  my-4'>
            <div className='card-body  '>
              <label className='col-lg-3 col-form-label fs-4 fw-bold h-90px'>Offer Notes</label>
              <div className='col-lg-9'>
                <div className='spinner spinner-sm spinner-primary spinner-right'></div>{' '}
              </div>
            </div>
          </div>
          <DetailSteps
            buyer={buyer}
            seller={seller}
            type={type}
            owner={owner}
            onChatOpen={() => setChatOpen(true)}
            chatWith={walletAccount === buyer ? seller : buyer}
            walletAccount={walletAccount}
            matchedBy={matchedby}
            order={offer}
            fiatTransfer={fiatTransfer}
            confirmPayment={confirmPayment}
          />
          {offer?.status !== 'COMPLETED' ||
            (offer?.status !== 'CANCELLED' && (
              <div className=' pt-5'>
                <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed min-w-lg-600px flex-shrink-0 p-6'>
                  <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                    <div className='mb-3 mb-md-0 fw-semibold'>
                      <span className='text-gray-800 fw-bold fs-7'>
                        Don’t wish to continue the transaction ?
                      </span>
                    </div>
                    <button
                      onClick={cancelTransaction}
                      disabled={offer?.status === 'COMPLETED' ? true : false}
                      className='btn btn-danger px-6 align-self-center text-nowrap'
                    >
                      Decline Order
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Panel
          styles={{
            content: {
              backgroundColor: mode === 'dark' ? "#000" : "#fff",
            },
          }}
          isFooterAtBottom
          isLightDismiss
          style={{ margin: 0, padding: 0 }}
          isOpen={chatOpen}
          onDismiss={() => {
            setChatOpen(false)
          }}
          // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
          closeButtonAriaLabel='Close'
        >
          <ChatContainer
            chatWith={walletAccount === buyer ? seller : buyer}
            onDismiss={() => {
              setChatOpen(false)
            }}
          />
        </Panel>
      </div>
      {/* end::Row */}
    </>
  )
}

const DetailPageWrapper = () => {
  const intl = useIntl()
  let { owner, matchedby, index, type } = useParams()

  useEffect(() => { }, [owner, matchedby, index, type])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{' P 2 P Dashboard'}</PageTitle>
      <DetailPage type={type} index={index} matchedby={matchedby} owner={owner} />
    </>
  )
}

export { DetailPageWrapper }
