/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useLayoutEffect } from 'react'
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from '../../../partials'
import _ from 'lodash'
import { CryptoAccountContext } from '../../../../app/modules/crypto/provider/CryptoAccountProvider'
import { Link } from 'react-router-dom'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { isWalletConnected, walletAccount, connectWallet }: any = useContext(CryptoAccountContext)

  useEffect(() => { }, [walletAccount])
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}

        {/* NOTIFICATIONS */}

        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Theme mode */}

        {/* begin::User */}
        {/* Activities */}
        {!walletAccount && (
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            {/* begin::Menu wrapper */}
            <span className='bullet bullet-dot bg-white   position-absolute translate-middle top-0 start-50 animation-blink'></span>
            <button
              className='btn btn-success px-6 align-self-center text-nowrap'
              onClick={(e: any) => {
                connectWallet()
              }}
            >
              Connect Wallet
            </button>
          </div>
        )}
        {walletAccount && (
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            <Link to={'/profile'} className='menu-link px-5'>

              <img
                className='h-30px w-30px rounded'
                src={toAbsoluteUrl('/media/avatars/300-2.jpg')}
                alt='metronic'
              />
              <span style={{ padding: 8, color: "white" }}>{
                _.truncate(walletAccount, {
                  length: 12,
                  omission: '***',
                })
              }</span>
            </Link>

          </div>
        )}
      </div>
    </div>
  )
}

export { Topbar }
